import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="Cloud Doc. Cloud documentation for executives, architects, and developers."
        />
      </Helmet>
      <div className="intro pb-4">
        <div className="container">
          <h1>Cloud Doc</h1>
          <h2 className="mb-1">Cloud documentation for all</h2>
          <p>
            For executives, architects, and developers who want to learn and/or share about the cloud,
            Cloud Doc provides a collaborative environment for writing, reviewing, and sharing 
            documentation related to it.
          </p>
        </div>
      </div>

      <div className="container mb-2">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-2">Featured work</h2>
          </div>
          <div className="col-12 col-md-4 mb-1">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>
                  <Link to="/articles/rosetta-salt">Rosetta Salt</Link>
                </h2>
                <p>
                  This article describes how to build your own password manager and deploy it into the AWS cloud.
                  It is made up of a serverless Python component that interacts with AWS Cognito, DynamoDB, 
	          and SES, a React browser client, and a React Native mobile client.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default Home;
